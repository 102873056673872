import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import HeadingBg from "@images/slider-img1.jpg"
import PageHeading from "@components/page-heading"
import { Container, Row, Col } from "reactstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Potency = () => {
  return (
    <Layout>
      <SEO title="Potency by HPLC" description="" />
      <PageHeading heading="Potency by HPLC" BgImg={HeadingBg} />
      <div className="page-section">
        <Container>
          <Row>
            <Col lg="6" className="d-flex align-items-center">
              {" "}
              <div>
                <h3 className="my-0 sub-heading">POTENCY TESTING</h3>
                <h2 className="mt-0 mb-4">
                  {" "}
                  Potency testing is important for the safety and knowledge of
                  the consumer.
                </h2>
                <p>
                  Using high-performance liquid chromatography with diode-array
                  detection (HPLC-DAD), we test for the 13 most commonly found
                  cannabinoids in marijuana. Testing is available at high and
                  low levels, allowing for reporting of low-level analytes,
                  giving the consumer a better-informed decision when factoring
                  in entourage effects.
                </p>
                <Row>
                  <Col lg="3" className="col-6">
                    <ul className="list-unstyled">
                      <li>CBC</li>
                      <li>CBD</li>
                      <li>CBDa</li>
                      <li>CBDV</li>
                    </ul>
                  </Col>
                  <Col lg="3" className="col-6">
                    <ul className="list-unstyled">
                      <li>CBG</li>
                      <li>CBGa</li>
                      <li>CBN</li>
                      <li>D-8THC</li>
                    </ul>
                  </Col>
                  <Col lg="3" className="col-6">
                    <ul className="list-unstyled">
                      <li>D9-THC</li>
                      <li>D10-THC</li>
                      <li>Exo-THC</li>
                      <li>THCa</li>
                    </ul>
                  </Col>{" "}
                  <Col lg="3" className="col-6">
                    <ul className="list-unstyled">
                      <li>THCV</li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg="6">
              <StaticImage
                src="../../images/terpene-page-img.jpg"
                alt="Terpenes Testing"
                quality={100}
                style={{ borderRadius: "10px" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Potency
